import {
  EuiModal,
  EuiModalHeader,
  EuiForm,
  EuiModalBody,
  EuiModalFooter,
  EuiFilePicker,
  EuiFlexItem,
  EuiLoadingSpinner,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { Button, H2, H3, H4 } from '../../../../App.style';
import {
  checkImportRecords,
  clearImportRecords,
  getCampaignRecordsListAsyncThunk,
  importRecordsAsyncThunk,
} from '../../api/campaignsSlice';
import { RecordImportFileResponse } from '../../../../../generated/tenants/Api';
import { InfoIcon } from '../../../../../resources/icons-new/InfoIcon';
import {
  getStrategyDetailedAsyncThunk,
  selectStrategyDetailed,
  selectStrategyId,
} from '../../../settings/adminPanel/components/strategies/api/strategiesSlice';
import { useTranslation } from 'react-i18next';

interface UploadRecordsModalProps {
  isVisible: boolean;
  campaignId: string;
  closeModal: () => void;
  openErrorPopup: (error: string) => void;
  closeErrorPopup: () => void;
}

const UploadRecordsModal: React.FC<UploadRecordsModalProps> = ({
  isVisible,
  campaignId,
  closeModal,
  openErrorPopup,
  closeErrorPopup,
}) => {
  const { t } = useTranslation();
  const strategy = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    dispatch(getStrategyDetailedAsyncThunk(strategy));
  }, []);

  const strategyDetailed = useAppSelector(selectStrategyDetailed);

  const [key, setKey] = useState<1 | 0>(0);

  const [fileError, setFileError] = useState<{
    format: boolean;
    blank: boolean;
  }>({ format: false, blank: false });

  const checkImport = useAppSelector(checkImportRecords);
  const apiError = checkImport.value?.error;

  strategyDetailed?.decipher_input_data;

  const handleFilePickerChange = (files: FileList | null) => {
    setFileError({ format: false, blank: false });
    if (files) {
      const fileName = files[0].name;
      const isCsv = fileName.endsWith('.csv');
      const isXlsx = fileName.endsWith('.xlsx');
      const isGpg =
        strategyDetailed?.decipher_input_data && fileName.endsWith('.gpg');
      if (!isCsv && !isGpg && !isXlsx) {
        //if file format is wrong
        setKey(key === 0 ? 1 : 0);
        setFileError({ blank: false, format: true });
      } else if (files[0].size === 0) {
        //if file is blank
        setKey(key === 0 ? 1 : 0);
        setFileError({ format: false, blank: true });
      } else {
        const data = {
          campaignId: campaignId,
          data: {
            file: files[0],
            file_name: files[0].name,
          },
        };
        //dispatches file and file_name, and opens modal
        dispatch(importRecordsAsyncThunk(data));
        setRecordsModalState('loading');

        //clears file picker after 2 seconds
        setTimeout(() => {
          setKey(key === 0 ? 1 : 0);
        }, 2000);
      }
    }
  };

  const [recordsModalState, setRecordsModalState] = useState<
    'upload' | 'loading' | 'result'
  >('upload');

  ///result
  const [importResult, setImportResult] = useState<RecordImportFileResponse>({
    file_errors: [],
    errors: [],
    number_of_imported_records: 0,
    number_of_source_lines: 0,
  });

  useEffect(() => {
    if (
      checkImport.state === 'idle' &&
      checkImport.value?.status === 'success' &&
      checkImport.value.result
    ) {
      //sets api response to state
      setImportResult(checkImport.value.result);

      //ends loading
      setRecordsModalState('result');

      //refreshes recordsList
      dispatch(getCampaignRecordsListAsyncThunk({ id: campaignId }));

      dispatch(clearImportRecords());
      closeErrorPopup();
    } else if (
      checkImport.state === 'idle' &&
      checkImport.value?.status === 'fail'
    ) {
      if (checkImport.value?.error) {
        openErrorPopup(checkImport.value.error[0]);
      }
      dispatch(clearImportRecords());
      handleCloseModal();
    }
  }, [checkImport]);

  const handleCloseModal = () => {
    setRecordsModalState('upload');
    closeModal();
  };

  useEffect(() => {
    if (isVisible) setFileError({ format: false, blank: false });
  }, [isVisible]);

  const recordsFailedLength =
    importResult.number_of_source_lines -
    importResult.number_of_imported_records;

  let uploadRecordsModal;
  if (isVisible) {
    uploadRecordsModal = (
      <EuiModal className="custom-modal" onClose={handleCloseModal}>
        {recordsModalState === 'upload' ? (
          <>
            <EuiModalHeader>
              <H2 $selected>{t('campaigns.records.list.uploadRecords')}</H2>
            </EuiModalHeader>

            <EuiForm component="form" id="add" onSubmit={handleSubmit}>
              <EuiModalBody>
                <div style={{ display: 'flex', gap: 3 }}>
                  <InfoIcon />
                  <H3 $grey>{t('campaigns.records.uploadModal.csv')}</H3>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 5,
                  }}
                >
                  <EuiFilePicker
                    key={`file-picker-${key}`}
                    isInvalid={
                      apiError ? true : fileError.blank || fileError.format
                    }
                    id={'file-picker-modal'}
                    multiple={false}
                    initialPromptText={t(
                      'campaigns.records.uploadModal.uploadImportHere',
                    )}
                    onChange={handleFilePickerChange}
                    display={'large'}
                    aria-label="csv-file-picker"
                  />

                  <EuiFlexItem>
                    {fileError.format ? (
                      <H3 color={'#FF0000'}>
                        {strategyDetailed?.decipher_input_data
                          ? t('campaigns.records.uploadModal.fileErrorGpg')
                          : t('campaigns.records.uploadModal.csv')}
                      </H3>
                    ) : fileError.blank ? (
                      <H3 color={'#FF0000'}>
                        {t('campaigns.records.uploadModal.fileErrorEmpty')}
                      </H3>
                    ) : (
                      checkImport.value?.error && (
                        <H3 color={'#FF0000'}>
                          {t(
                            'campaigns.records.uploadModal.somethingWentWrong',
                          )}
                        </H3>
                      )
                    )}
                  </EuiFlexItem>
                </div>
              </EuiModalBody>

              <EuiModalFooter>
                <Button $styleType="NORMAL" onClick={handleCloseModal}>
                  <H3>{t('common.cancel')}</H3>
                </Button>
              </EuiModalFooter>
            </EuiForm>
          </>
        ) : recordsModalState === 'loading' ? (
          <>
            <EuiModalHeader>
              <H2 $selected>
                {t('campaigns.records.uploadModal.uploadingRecords')}
              </H2>
            </EuiModalHeader>
            <EuiModalBody style={{ alignItems: 'center' }}>
              <EuiLoadingSpinner size="xxl" />
            </EuiModalBody>
          </>
        ) : (
          recordsModalState === 'result' && (
            <>
              <EuiModalHeader>
                <H2 $selected>
                  {t('campaigns.records.uploadModal.verifiedRecords')}
                </H2>
              </EuiModalHeader>
              <EuiModalBody style={{ alignItems: 'center' }}>
                <div
                  style={{
                    border: '1px solid #983700',
                    width: 400,
                    borderRadius: 5,
                  }}
                >
                  <H3 style={{ padding: 8 }}>
                    {t('campaigns.records.uploadModal.recordsFailed')}
                    <b>{recordsFailedLength}</b>
                  </H3>

                  <div
                    style={{
                      width: '100%',
                      borderTop:
                        importResult.errors.length > 0
                          ? '1px solid #983700'
                          : '',
                      overflow: 'auto',
                      maxHeight: 150,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 8,
                    }}
                  >
                    {importResult.errors.map((error) => {
                      return (
                        <div style={{ display: 'flex', padding: 8 }}>
                          <div
                            style={{ display: 'flex', width: '20%', gap: 8 }}
                          >
                            <H4>{t('campaigns.records.uploadModal.row')}</H4>{' '}
                            <H4>{error.line_number}</H4>
                          </div>
                          <div>{error.message}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    marginTop: 12,
                    border: '1px solid #127FBF',
                    // maxHeight: 200,
                    width: 400,
                    borderRadius: 5,
                  }}
                >
                  <H3 style={{ padding: 8 }}>
                    {t('campaigns.records.uploadModal.recordsVerified')}
                    <b>{importResult.number_of_imported_records}</b>
                  </H3>
                </div>
              </EuiModalBody>

              <EuiModalFooter>
                <Button color="white" $size="M" onClick={handleCloseModal}>
                  {t('common.close')}
                </Button>
              </EuiModalFooter>
            </>
          )
        )}
      </EuiModal>
    );
  }
  return <>{uploadRecordsModal}</>;
};

export default UploadRecordsModal;
